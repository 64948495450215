import React, { createContext, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { UseQueryType } from "../../../types/ErrorMessageType";
import { useQuery } from "@tanstack/react-query";
import { getBpwSettings } from "../../bpw/utils/bpwRequests";
import { useUserContext } from "../user/UserContext";
import { useAuth0 } from "@auth0/auth0-react";
import { CompanyIdOrDefault } from "../../../utils/helpers/UserHelper";
import {
  BpwSettings,
  DepartmentTypeEnum,
  empty,
  ManageDepartment,
  MemberCompanyInformation,
  objectFieldValidator,
} from "@nexstar-network/shared-utils";
import { LoadingOverlay } from "../../../utils/components/tailwind/LoadingOverlay";
import { addMessage } from "../../../utils/helpers/MessagingHelper";
import { useSnackbar } from "notistack";
import { getCompanyManagement } from "../../management/utils/companyManagementRequests";

const BPWContext = createContext<{
  departmentsForBPW: ManageDepartment[];
  selectedDepartment: ManageDepartment;
  selectedDepartmentId: string;
  selectedDepartmentType: DepartmentTypeEnum | "OVERALL";
  companyInfoForBPW: MemberCompanyInformation;
  changeSelectedDepartment: (dept: ManageDepartment) => void;
  bpwFiscalYear: number;
  bpwSettings: BpwSettings;
  loadingCompanyInformation: boolean;
}>(undefined);

const getBPWYear = (routeParams, bpwFiscalYear) => {
  if (routeParams && routeParams.includes("/")) {
    const fiscalYear = routeParams.split("/").shift();
    return Number(fiscalYear);
  }
  if (bpwFiscalYear && empty(routeParams)) return bpwFiscalYear;
  else {
    return new Date().getFullYear();
  }
};

export const BPWProvider = ({ children }: any) => {
  const { selectedCompany, getUser } = useUserContext();
  const { getAccessTokenSilently } = useAuth0();
  const { enqueueSnackbar } = useSnackbar();
  const memberId = CompanyIdOrDefault(getUser(), selectedCompany).toString();
  const { "*": splat } = useParams();
  const [loading, setLoading] = useState(false);
  const [bpwFiscalYear, setBpwFiscalYear] = useState<number>(null);
  const [companyInfoForBPW, setCompanyInfoForBpw] =
    useState<MemberCompanyInformation>(null);
  const [departmentsForBPW, setDepartmentsForBPW] =
    useState<ManageDepartment[]>(null);
  const [selectedDepartment, setSelectedDepartment] =
    useState<ManageDepartment>(null);
  const [selectedDepartmentId, setSelectedDepartmentId] =
    useState<string>(null);
  const [selectedDepartmentType, setSelectedDepartmentType] = useState<
    DepartmentTypeEnum | "OVERALL"
  >(null);

  const {
    data: companyInfo,
    isLoading: loadingCompanyInformation,
    error: companyInfoError,
  }: UseQueryType<any> = useQuery({
    queryKey: ["CompanyManagement", { memberId, year: bpwFiscalYear }],
    queryFn: () =>
      getCompanyManagement(
        { memberId, year: bpwFiscalYear },
        false,
        getAccessTokenSilently,
      ),
    staleTime: Infinity,
    enabled: !!bpwFiscalYear,
  });

  const {
    data: bpwSettings,
    error: bpwSettingsError,
    isLoading: loadingBpwSettings,
  }: UseQueryType<any> = useQuery({
    queryKey: ["BPWSETTINGS", { memberId, year: bpwFiscalYear }],
    queryFn: () =>
      getBpwSettings({ memberId, year: bpwFiscalYear }, getAccessTokenSilently),
    staleTime: Infinity,
    enabled: !!bpwFiscalYear,
  });

  const getCompanyInfoForBpw = () => {
    if (
      objectFieldValidator(companyInfo, [
        "getAllMemberCompanyInformationByYear",
        "departmentInformation",
      ])
    ) {
      return companyInfo.getAllMemberCompanyInformationByYear;
    }
    return null;
  };

  const changeSelectedDepartment = (selectedDept: ManageDepartment) => {
    setSelectedDepartment(selectedDept);
    setSelectedDepartmentId(selectedDept.id.split("#").pop());
    setSelectedDepartmentType(selectedDept.departmentType);
  };

  useEffect(() => {
    const info = getCompanyInfoForBpw();
    if (!empty(info)) {
      setCompanyInfoForBpw(info.companyInformation);
      if (info.departmentInformation.length > 0) {
        const sortedDepartments = info.departmentInformation.sort(
          (a, b) => a.departmentOrder - b.departmentOrder,
        );
        setDepartmentsForBPW(sortedDepartments);
        changeSelectedDepartment(sortedDepartments[0]);
      }
    }
    setLoading(false);
  }, [loadingCompanyInformation, bpwFiscalYear, loading]);

  useEffect(() => {
    if (empty(splat)) {
      // TP-1995: BK -- we only want to set loading to true if you go to the BPW dashboard. If you are within BPW and set loading === true, then the useEffect that resets the selected department will run, and we want the department to persist as you navigate around the tool
      setLoading(true);
    }
    const fiscalYear = getBPWYear(splat, bpwFiscalYear);
    setBpwFiscalYear(fiscalYear);
  }, [splat]);

  if (companyInfoError) {
    addMessage(companyInfoError.message, "error", enqueueSnackbar);
    throw new Error(companyInfoError.message);
  }
  if (bpwSettingsError) {
    addMessage(bpwSettingsError.message, "error", enqueueSnackbar);
    throw new Error(bpwSettingsError.message);
  }

  if (loadingCompanyInformation || loadingBpwSettings || loading) {
    return (
      <LoadingOverlay
        loading
        text={
          process.env.REACT_APP_NODE_ENV !== "development"
            ? "Loading Nexstar Tools"
            : "Loading BPWContext"
        }
      />
    );
  }

  return (
    <BPWContext.Provider
      value={{
        departmentsForBPW,
        selectedDepartment,
        selectedDepartmentId,
        selectedDepartmentType,
        companyInfoForBPW,
        changeSelectedDepartment,
        bpwFiscalYear,
        bpwSettings,
        loadingCompanyInformation,
      }}
    >
      {children}
    </BPWContext.Provider>
  );
};

export const useBPWContext = () => useContext(BPWContext);
