"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createMPWWorkshopData = exports.createMPWPreworkData = exports.productionStagesInfo = exports.productionStages = exports.targetAudienceOptions = exports.orderMarketingWorksheetSections = exports.marketingPlanItemDefaultData = exports.marketingWorksheetSections = exports.createTrackingData = exports.createFutureMarketingPlanItem = exports.createCustomMarketingWorksheetItem = void 0;
const mpwHelpers_1 = require("./mpwHelpers");
const ulid_1 = require("ulid");
// TODO TP-1850: Updated this to set theID pattern from BE
function createCustomMarketingWorksheetItem(memberId, fiscalYear, groupKey, groupId, idx) {
    return {
        fiscalYear,
        groupId,
        memberId,
        secondaryId: (0, mpwHelpers_1.generateGroupSK)(`MPW#${fiscalYear}#WORKSHOP`, groupKey, idx),
        id: (0, mpwHelpers_1.generateGroupSK)(`MPW#${fiscalYear}#WORKSHOP`, groupKey, groupId),
        tactic: "",
        notes: null,
        numberOfPrograms: null,
        totalAnnualCosts: null,
        priority: null,
        use: false,
        custom: true,
        futureMarketingPlan: [],
    };
}
exports.createCustomMarketingWorksheetItem = createCustomMarketingWorksheetItem;
// TODO TP-1850: updated this to build the new id format
/**
 *
 * @param memberId
 * @param fiscalYear
 * @param base
 * @param fmpId
 * @param idx
 */
function createFutureMarketingPlanItem(memberId, fiscalYear, base, fmpId, idx) {
    return {
        fiscalYear,
        fmpId: fmpId,
        memberId,
        secondaryId: (0, mpwHelpers_1.generateFMPKey)(base, idx),
        id: (0, mpwHelpers_1.generateFMPKey)(base, fmpId),
        campaignName: null,
        reasonWhySelected: null,
        goal: null,
        requiresProduction: null,
        targetAudience: null,
        estimatedAnnualCampaignCost: null,
        productionChart: {
            designAndWrite: {
                vendor: null,
                startDate: null,
                completionDate: null,
            },
            printBroadcastProduction: {
                vendor: null,
                startDate: null,
                completionDate: null,
            },
            purchaseListOrMedia: {
                vendor: null,
                startDate: null,
                completionDate: null,
            },
            orderProduct: {
                vendor: null,
                startDate: null,
                completionDate: null,
            },
        },
        implementationChart: {
            jan: null,
            feb: null,
            mar: null,
            apr: null,
            may: null,
            jun: null,
            jul: null,
            aug: null,
            sep: null,
            oct: null,
            nov: null,
            dec: null,
        },
        trackingData: createTrackingData(),
    };
}
exports.createFutureMarketingPlanItem = createFutureMarketingPlanItem;
function createTrackingData() {
    return {
        assessment: null,
        continueToUse: null,
        opportunityForImprovement: null,
        monthlyData: {
            jan: {
                actualCost: null,
                totalCalls: null,
                callsRan: null,
                revenue: null,
            },
            feb: {
                actualCost: null,
                totalCalls: null,
                callsRan: null,
                revenue: null,
            },
            mar: {
                actualCost: null,
                totalCalls: null,
                callsRan: null,
                revenue: null,
            },
            apr: {
                actualCost: null,
                totalCalls: null,
                callsRan: null,
                revenue: null,
            },
            may: {
                actualCost: null,
                totalCalls: null,
                callsRan: null,
                revenue: null,
            },
            jun: {
                actualCost: null,
                totalCalls: null,
                callsRan: null,
                revenue: null,
            },
            jul: {
                actualCost: null,
                totalCalls: null,
                callsRan: null,
                revenue: null,
            },
            aug: {
                actualCost: null,
                totalCalls: null,
                callsRan: null,
                revenue: null,
            },
            sep: {
                actualCost: null,
                totalCalls: null,
                callsRan: null,
                revenue: null,
            },
            oct: {
                actualCost: null,
                totalCalls: null,
                callsRan: null,
                revenue: null,
            },
            nov: {
                actualCost: null,
                totalCalls: null,
                callsRan: null,
                revenue: null,
            },
            dec: {
                actualCost: null,
                totalCalls: null,
                callsRan: null,
                revenue: null,
            },
        },
    };
}
exports.createTrackingData = createTrackingData;
exports.marketingWorksheetSections = {
    basics: "The Basics",
    retainAndGrowCurrentCustomers: "Retain and Grow Current Customers",
    guerrillaMarketingIdeas: "Attract New Customers: Guerrilla Marketing Ideas",
    massMarketingVehicles: "Attract New Customers: Mass Marketing Vehicles",
    digitalMarketing: "Online Marketing",
    additionalIdeas: "Additional Ideas",
};
exports.marketingPlanItemDefaultData = {
    marketingTool: null,
    goal: null,
    campaignName: null,
    totalCalls: null,
    callsRan: null,
    actualCost: null,
    revenue: null,
    assessment: null,
    opportunityForImprovement: null,
    continueToUse: null,
    id: null,
};
exports.orderMarketingWorksheetSections = Object.keys(exports.marketingWorksheetSections);
exports.targetAudienceOptions = {
    current: "Current",
    prospect: "Prospect",
    both: "Both",
};
exports.productionStages = [
    "designAndWrite",
    "printBroadcastProduction",
    "purchaseListOrMedia",
    "orderProduct",
];
exports.productionStagesInfo = {
    designAndWrite: "Design & Write",
    printBroadcastProduction: "Print/Broadcast Production",
    purchaseListOrMedia: "Purchase List or Media",
    orderProduct: "Order Product",
};
function createMPWPreworkData(memberId, year) {
    return {
        PK: `MEMBER#${memberId}`,
        fiscalYear: year,
        SK: `MPW#${year}#PREWORK`,
        historicalSalesProfits: {
            yearMinusOne: {
                year: (year - 1).toString(),
                annualSales: null,
                netProfit: null,
                annualSalesChange: null,
                netProfitChange: null,
            },
            yearMinusTwo: {
                year: (year - 2).toString(),
                annualSales: null,
                netProfit: null,
                annualSalesChange: null,
                netProfitChange: null,
            },
            yearMinusThree: {
                year: (year - 3).toString(),
                annualSales: null,
                netProfit: null,
                annualSalesChange: null,
                netProfitChange: null,
            },
            yearMinusFour: {
                year: (year - 4).toString(),
                annualSales: null,
                netProfit: null,
                annualSalesChange: null,
                netProfitChange: null,
            },
            yearMinusFive: {
                year: (year - 5).toString(),
                annualSales: null,
                netProfit: null,
                annualSalesChange: null,
                netProfitChange: null,
            },
        },
        factorsInfluencedChange: {
            items: ["", "", ""],
        },
        marketingEffectivenessConcerns: {
            items: ["", "", ""],
        },
        strengths: {
            items: ["", "", ""],
        },
        weaknesses: {
            items: ["", "", ""],
        },
        opportunities: {
            items: ["", "", ""],
        },
        threats: {
            items: ["", "", ""],
        },
        keyIssues: {
            items: ["", "", ""],
        },
        financialGoals: {
            items: ["", "", ""],
        },
        marketingGoals: {
            items: ["", "", ""],
        },
        currentCustomerProfile: {
            items: ["", "", ""],
        },
        prospectProfile: {
            items: ["", "", ""],
        },
        customerOrProspectWants: {
            items: ["", "", ""],
        },
        numberOfCompetitors: null,
        primaryCompetitorDescriptions: {
            items: ["", "", ""],
        },
        competitorMarketingNotes: {
            items: ["", "", ""],
        },
        competitorStrengths: {
            items: ["", "", ""],
        },
        competitorWeaknesses: {
            items: ["", "", ""],
        },
        currentMarketingPlan: [],
    };
}
exports.createMPWPreworkData = createMPWPreworkData;
function createBasics(memberId, year) {
    const ulid0 = (0, ulid_1.ulid)();
    const ulid1 = (0, ulid_1.ulid)();
    const ulid2 = (0, ulid_1.ulid)();
    const ulid3 = (0, ulid_1.ulid)();
    const ulid4 = (0, ulid_1.ulid)();
    const ulid5 = (0, ulid_1.ulid)();
    const ulid6 = (0, ulid_1.ulid)();
    const ulid7 = (0, ulid_1.ulid)();
    const ulid8 = (0, ulid_1.ulid)();
    const ulid9 = (0, ulid_1.ulid)();
    return [
        {
            id: `MPW#${year}#WORKSHOP#BASICS#${ulid0}`,
            memberId: `MEMBER#${memberId}`,
            secondaryId: `MPW#${year}#WORKSHOP#BASICS#0`,
            groupId: ulid0,
            fiscalYear: year,
            tactic: "Company Name (new or revision)",
            notes: null,
            numberOfPrograms: null,
            totalAnnualCosts: null,
            priority: null,
            use: false,
            custom: false,
            futureMarketingPlan: [],
        },
        {
            id: `MPW#${year}#WORKSHOP#BASICS#${ulid1}`,
            memberId: `MEMBER#${memberId}`,
            secondaryId: `MPW#${year}#WORKSHOP#BASICS#1`,
            groupId: ulid1,
            fiscalYear: year,
            tactic: "Logo (new or revision)",
            notes: null,
            numberOfPrograms: null,
            totalAnnualCosts: null,
            priority: null,
            use: false,
            custom: false,
            futureMarketingPlan: [],
        },
        {
            id: `MPW#${year}#WORKSHOP#BASICS#${ulid2}`,
            memberId: `MEMBER#${memberId}`,
            secondaryId: `MPW#${year}#WORKSHOP#BASICS#2`,
            groupId: ulid2,
            fiscalYear: year,
            tactic: "Slogan (new or revision)",
            notes: null,
            numberOfPrograms: null,
            totalAnnualCosts: null,
            priority: null,
            use: false,
            custom: false,
            futureMarketingPlan: [],
        },
        {
            id: `MPW#${year}#WORKSHOP#BASICS#${ulid3}`,
            memberId: `MEMBER#${memberId}`,
            secondaryId: `MPW#${year}#WORKSHOP#BASICS#3`,
            groupId: ulid3,
            fiscalYear: year,
            tactic: "Business Cards and Letterhead",
            notes: null,
            numberOfPrograms: null,
            totalAnnualCosts: null,
            priority: null,
            use: false,
            custom: false,
            futureMarketingPlan: [],
        },
        {
            id: `MPW#${year}#WORKSHOP#BASICS#${ulid4}`,
            memberId: `MEMBER#${memberId}`,
            secondaryId: `MPW#${year}#WORKSHOP#BASICS#4`,
            groupId: ulid4,
            fiscalYear: year,
            tactic: "Stickers",
            notes: null,
            numberOfPrograms: null,
            totalAnnualCosts: null,
            priority: null,
            use: false,
            custom: false,
            futureMarketingPlan: [],
        },
        {
            id: `MPW#${year}#WORKSHOP#BASICS#${ulid5}`,
            memberId: `MEMBER#${memberId}`,
            secondaryId: `MPW#${year}#WORKSHOP#BASICS#5`,
            groupId: ulid5,
            fiscalYear: year,
            tactic: "Magnets",
            notes: null,
            numberOfPrograms: null,
            totalAnnualCosts: null,
            priority: null,
            use: false,
            custom: false,
            futureMarketingPlan: [],
        },
        {
            id: `MPW#${year}#WORKSHOP#BASICS#${ulid6}`,
            memberId: `MEMBER#${memberId}`,
            secondaryId: `MPW#${year}#WORKSHOP#BASICS#6`,
            groupId: ulid6,
            fiscalYear: year,
            tactic: "Presentation Folders",
            notes: null,
            numberOfPrograms: null,
            totalAnnualCosts: null,
            priority: null,
            use: false,
            custom: false,
            futureMarketingPlan: [],
        },
        {
            id: `MPW#${year}#WORKSHOP#BASICS#${ulid7}`,
            memberId: `MEMBER#${memberId}`,
            secondaryId: `MPW#${year}#WORKSHOP#BASICS#7`,
            groupId: ulid7,
            fiscalYear: year,
            tactic: "Yard Signs",
            notes: null,
            numberOfPrograms: null,
            totalAnnualCosts: null,
            priority: null,
            use: false,
            custom: false,
            futureMarketingPlan: [],
        },
        {
            id: `MPW#${year}#WORKSHOP#BASICS#${ulid8}`,
            memberId: `MEMBER#${memberId}`,
            secondaryId: `MPW#${year}#WORKSHOP#BASICS#8`,
            groupId: ulid8,
            fiscalYear: year,
            tactic: "Thank-You Notes",
            notes: null,
            numberOfPrograms: null,
            totalAnnualCosts: null,
            priority: null,
            use: false,
            custom: false,
            futureMarketingPlan: [],
        },
        {
            id: `MPW#${year}#WORKSHOP#BASICS#${ulid9}`,
            memberId: `MEMBER#${memberId}`,
            secondaryId: `MPW#${year}#WORKSHOP#BASICS#9`,
            groupId: ulid9,
            fiscalYear: year,
            tactic: "Truck Graphics",
            notes: null,
            numberOfPrograms: null,
            totalAnnualCosts: null,
            priority: null,
            use: false,
            custom: false,
            futureMarketingPlan: [],
        },
    ];
}
function createRetainAndGrowCurrentCustomers(memberId, year) {
    const ulid0 = (0, ulid_1.ulid)();
    const ulid1 = (0, ulid_1.ulid)();
    const ulid2 = (0, ulid_1.ulid)();
    const ulid3 = (0, ulid_1.ulid)();
    const ulid4 = (0, ulid_1.ulid)();
    const ulid5 = (0, ulid_1.ulid)();
    const ulid6 = (0, ulid_1.ulid)();
    const ulid7 = (0, ulid_1.ulid)();
    const ulid8 = (0, ulid_1.ulid)();
    const ulid9 = (0, ulid_1.ulid)();
    const ulid10 = (0, ulid_1.ulid)();
    const ulid11 = (0, ulid_1.ulid)();
    const ulid12 = (0, ulid_1.ulid)();
    const ulid13 = (0, ulid_1.ulid)();
    return [
        {
            id: `MPW#${year}#WORKSHOP#RETAINANDGROWCURRENTCUSTOMERS#${ulid0}`,
            memberId: `MEMBER#${memberId}`,
            secondaryId: `MPW#${year}#WORKSHOP#RETAINANDGROWCURRENTCUSTOMERS#0`,
            groupId: ulid0,
            fiscalYear: year,
            tactic: "Direct Mail",
            notes: null,
            numberOfPrograms: null,
            totalAnnualCosts: null,
            priority: null,
            use: false,
            custom: false,
            futureMarketingPlan: [],
        },
        {
            id: `MPW#${year}#WORKSHOP#RETAINANDGROWCURRENTCUSTOMERS#${ulid1}`,
            memberId: `MEMBER#${memberId}`,
            secondaryId: `MPW#${year}#WORKSHOP#RETAINANDGROWCURRENTCUSTOMERS#1`,
            groupId: ulid1,
            fiscalYear: year,
            tactic: "Holiday Cards",
            notes: null,
            numberOfPrograms: null,
            totalAnnualCosts: null,
            priority: null,
            use: false,
            custom: false,
            futureMarketingPlan: [],
        },
        {
            id: `MPW#${year}#WORKSHOP#RETAINANDGROWCURRENTCUSTOMERS#${ulid2}`,
            memberId: `MEMBER#${memberId}`,
            secondaryId: `MPW#${year}#WORKSHOP#RETAINANDGROWCURRENTCUSTOMERS#2`,
            groupId: ulid2,
            fiscalYear: year,
            tactic: "Newsletters",
            notes: null,
            numberOfPrograms: null,
            totalAnnualCosts: null,
            priority: null,
            use: false,
            custom: false,
            futureMarketingPlan: [],
        },
        {
            id: `MPW#${year}#WORKSHOP#RETAINANDGROWCURRENTCUSTOMERS#${ulid3}`,
            memberId: `MEMBER#${memberId}`,
            secondaryId: `MPW#${year}#WORKSHOP#RETAINANDGROWCURRENTCUSTOMERS#3`,
            groupId: ulid3,
            fiscalYear: year,
            tactic: "Company Brochure",
            notes: null,
            numberOfPrograms: null,
            totalAnnualCosts: null,
            priority: null,
            use: false,
            custom: false,
            futureMarketingPlan: [],
        },
        {
            id: `MPW#${year}#WORKSHOP#RETAINANDGROWCURRENTCUSTOMERS#${ulid4}`,
            memberId: `MEMBER#${memberId}`,
            secondaryId: `MPW#${year}#WORKSHOP#RETAINANDGROWCURRENTCUSTOMERS#4`,
            groupId: ulid4,
            fiscalYear: year,
            tactic: "Email Campaigns",
            notes: null,
            numberOfPrograms: null,
            totalAnnualCosts: null,
            priority: null,
            use: false,
            custom: false,
            futureMarketingPlan: [],
        },
        {
            id: `MPW#${year}#WORKSHOP#RETAINANDGROWCURRENTCUSTOMERS#${ulid5}`,
            memberId: `MEMBER#${memberId}`,
            secondaryId: `MPW#${year}#WORKSHOP#RETAINANDGROWCURRENTCUSTOMERS#5`,
            groupId: ulid5,
            fiscalYear: year,
            tactic: "Text Messaging",
            notes: null,
            numberOfPrograms: null,
            totalAnnualCosts: null,
            priority: null,
            use: false,
            custom: false,
            futureMarketingPlan: [],
        },
        {
            id: `MPW#${year}#WORKSHOP#RETAINANDGROWCURRENTCUSTOMERS#${ulid6}`,
            memberId: `MEMBER#${memberId}`,
            secondaryId: `MPW#${year}#WORKSHOP#RETAINANDGROWCURRENTCUSTOMERS#6`,
            groupId: ulid6,
            fiscalYear: year,
            tactic: "Gift Cards",
            notes: null,
            numberOfPrograms: null,
            totalAnnualCosts: null,
            priority: null,
            use: false,
            custom: false,
            futureMarketingPlan: [],
        },
        {
            id: `MPW#${year}#WORKSHOP#RETAINANDGROWCURRENTCUSTOMERS#${ulid7}`,
            memberId: `MEMBER#${memberId}`,
            secondaryId: `MPW#${year}#WORKSHOP#RETAINANDGROWCURRENTCUSTOMERS#7`,
            groupId: ulid7,
            fiscalYear: year,
            tactic: "In-Home Flyers",
            notes: null,
            numberOfPrograms: null,
            totalAnnualCosts: null,
            priority: null,
            use: false,
            custom: false,
            futureMarketingPlan: [],
        },
        {
            id: `MPW#${year}#WORKSHOP#RETAINANDGROWCURRENTCUSTOMERS#${ulid8}`,
            memberId: `MEMBER#${memberId}`,
            secondaryId: `MPW#${year}#WORKSHOP#RETAINANDGROWCURRENTCUSTOMERS#8`,
            groupId: ulid8,
            fiscalYear: year,
            tactic: "Buy one get one (BOGO)",
            notes: null,
            numberOfPrograms: null,
            totalAnnualCosts: null,
            priority: null,
            use: false,
            custom: false,
            futureMarketingPlan: [],
        },
        {
            id: `MPW#${year}#WORKSHOP#RETAINANDGROWCURRENTCUSTOMERS#${ulid9}`,
            memberId: `MEMBER#${memberId}`,
            secondaryId: `MPW#${year}#WORKSHOP#RETAINANDGROWCURRENTCUSTOMERS#9`,
            groupId: ulid9,
            fiscalYear: year,
            tactic: "SPP Welcome Pack/Letter/Gift",
            notes: null,
            numberOfPrograms: null,
            totalAnnualCosts: null,
            priority: null,
            use: false,
            custom: false,
            futureMarketingPlan: [],
        },
        {
            id: `MPW#${year}#WORKSHOP#RETAINANDGROWCURRENTCUSTOMERS#${ulid10}`,
            memberId: `MEMBER#${memberId}`,
            secondaryId: `MPW#${year}#WORKSHOP#RETAINANDGROWCURRENTCUSTOMERS#10`,
            groupId: ulid10,
            fiscalYear: year,
            tactic: "SPP Unexpected Gift",
            notes: null,
            numberOfPrograms: null,
            totalAnnualCosts: null,
            priority: null,
            use: false,
            custom: false,
            futureMarketingPlan: [],
        },
        {
            id: `MPW#${year}#WORKSHOP#RETAINANDGROWCURRENTCUSTOMERS#${ulid11}`,
            memberId: `MEMBER#${memberId}`,
            secondaryId: `MPW#${year}#WORKSHOP#RETAINANDGROWCURRENTCUSTOMERS#11`,
            groupId: ulid11,
            fiscalYear: year,
            tactic: "SPP Exclusive Offers: Direct Mail",
            notes: null,
            numberOfPrograms: null,
            totalAnnualCosts: null,
            priority: null,
            use: false,
            custom: false,
            futureMarketingPlan: [],
        },
        {
            id: `MPW#${year}#WORKSHOP#RETAINANDGROWCURRENTCUSTOMERS#${ulid12}`,
            memberId: `MEMBER#${memberId}`,
            secondaryId: `MPW#${year}#WORKSHOP#RETAINANDGROWCURRENTCUSTOMERS#12`,
            groupId: ulid12,
            fiscalYear: year,
            tactic: "SPP Exclusive Offers: Email",
            notes: null,
            numberOfPrograms: null,
            totalAnnualCosts: null,
            priority: null,
            use: false,
            custom: false,
            futureMarketingPlan: [],
        },
        {
            id: `MPW#${year}#WORKSHOP#RETAINANDGROWCURRENTCUSTOMERS#${ulid13}`,
            memberId: `MEMBER#${memberId}`,
            secondaryId: `MPW#${year}#WORKSHOP#RETAINANDGROWCURRENTCUSTOMERS#13`,
            groupId: ulid13,
            fiscalYear: year,
            tactic: "SPP Brochure",
            notes: null,
            numberOfPrograms: null,
            totalAnnualCosts: null,
            priority: null,
            use: false,
            custom: false,
            futureMarketingPlan: [],
        },
    ];
}
function createGuerrillaMarketingIdeas(memberId, year) {
    const ulid0 = (0, ulid_1.ulid)();
    const ulid1 = (0, ulid_1.ulid)();
    const ulid2 = (0, ulid_1.ulid)();
    const ulid3 = (0, ulid_1.ulid)();
    const ulid4 = (0, ulid_1.ulid)();
    const ulid5 = (0, ulid_1.ulid)();
    const ulid6 = (0, ulid_1.ulid)();
    const ulid7 = (0, ulid_1.ulid)();
    const ulid8 = (0, ulid_1.ulid)();
    const ulid9 = (0, ulid_1.ulid)();
    const ulid10 = (0, ulid_1.ulid)();
    const ulid11 = (0, ulid_1.ulid)();
    const ulid12 = (0, ulid_1.ulid)();
    const ulid13 = (0, ulid_1.ulid)();
    return [
        {
            id: `MPW#${year}#WORKSHOP#GUERRILLAMARKETINGIDEAS#${ulid0}`,
            memberId: `MEMBER#${memberId}`,
            secondaryId: `MPW#${year}#WORKSHOP#GUERRILLAMARKETINGIDEAS#0`,
            groupId: ulid0,
            fiscalYear: year,
            tactic: "Magnet Mailers / Sticky Note Mailers",
            notes: null,
            numberOfPrograms: null,
            totalAnnualCosts: null,
            priority: null,
            use: false,
            custom: false,
            futureMarketingPlan: [],
        },
        {
            id: `MPW#${year}#WORKSHOP#GUERRILLAMARKETINGIDEAS#${ulid1}`,
            memberId: `MEMBER#${memberId}`,
            secondaryId: `MPW#${year}#WORKSHOP#GUERRILLAMARKETINGIDEAS#1`,
            groupId: ulid1,
            fiscalYear: year,
            tactic: "Referral Program",
            notes: null,
            numberOfPrograms: null,
            totalAnnualCosts: null,
            priority: null,
            use: false,
            custom: false,
            futureMarketingPlan: [],
        },
        {
            id: `MPW#${year}#WORKSHOP#GUERRILLAMARKETINGIDEAS#${ulid2}`,
            memberId: `MEMBER#${memberId}`,
            secondaryId: `MPW#${year}#WORKSHOP#GUERRILLAMARKETINGIDEAS#2`,
            groupId: ulid2,
            fiscalYear: year,
            tactic: "Common Problems / Situation Letters",
            notes: null,
            numberOfPrograms: null,
            totalAnnualCosts: null,
            priority: null,
            use: false,
            custom: false,
            futureMarketingPlan: [],
        },
        {
            id: `MPW#${year}#WORKSHOP#GUERRILLAMARKETINGIDEAS#${ulid3}`,
            memberId: `MEMBER#${memberId}`,
            secondaryId: `MPW#${year}#WORKSHOP#GUERRILLAMARKETINGIDEAS#3`,
            groupId: ulid3,
            fiscalYear: year,
            tactic: "New Homeowners Letter",
            notes: null,
            numberOfPrograms: null,
            totalAnnualCosts: null,
            priority: null,
            use: false,
            custom: false,
            futureMarketingPlan: [],
        },
        {
            id: `MPW#${year}#WORKSHOP#GUERRILLAMARKETINGIDEAS#${ulid4}`,
            memberId: `MEMBER#${memberId}`,
            secondaryId: `MPW#${year}#WORKSHOP#GUERRILLAMARKETINGIDEAS#4`,
            groupId: ulid4,
            fiscalYear: year,
            tactic: "Auctions",
            notes: null,
            numberOfPrograms: null,
            totalAnnualCosts: null,
            priority: null,
            use: false,
            custom: false,
            futureMarketingPlan: [],
        },
        {
            id: `MPW#${year}#WORKSHOP#GUERRILLAMARKETINGIDEAS#${ulid5}`,
            memberId: `MEMBER#${memberId}`,
            secondaryId: `MPW#${year}#WORKSHOP#GUERRILLAMARKETINGIDEAS#5`,
            groupId: ulid5,
            fiscalYear: year,
            tactic: "Networking Clubs",
            notes: null,
            numberOfPrograms: null,
            totalAnnualCosts: null,
            priority: null,
            use: false,
            custom: false,
            futureMarketingPlan: [],
        },
        {
            id: `MPW#${year}#WORKSHOP#GUERRILLAMARKETINGIDEAS#${ulid6}`,
            memberId: `MEMBER#${memberId}`,
            secondaryId: `MPW#${year}#WORKSHOP#GUERRILLAMARKETINGIDEAS#6`,
            groupId: ulid6,
            fiscalYear: year,
            tactic: "Church, School and Other Non-Profit Programs",
            notes: null,
            numberOfPrograms: null,
            totalAnnualCosts: null,
            priority: null,
            use: false,
            custom: false,
            futureMarketingPlan: [],
        },
        {
            id: `MPW#${year}#WORKSHOP#GUERRILLAMARKETINGIDEAS#${ulid7}`,
            memberId: `MEMBER#${memberId}`,
            secondaryId: `MPW#${year}#WORKSHOP#GUERRILLAMARKETINGIDEAS#7`,
            groupId: ulid7,
            fiscalYear: year,
            tactic: "Community Marketing",
            notes: null,
            numberOfPrograms: null,
            totalAnnualCosts: null,
            priority: null,
            use: false,
            custom: false,
            futureMarketingPlan: [],
        },
        {
            id: `MPW#${year}#WORKSHOP#GUERRILLAMARKETINGIDEAS#${ulid8}`,
            memberId: `MEMBER#${memberId}`,
            secondaryId: `MPW#${year}#WORKSHOP#GUERRILLAMARKETINGIDEAS#8`,
            groupId: ulid8,
            fiscalYear: year,
            tactic: "Cause Marketing Programs (e.g., Breast Cancer Awareness)",
            notes: null,
            numberOfPrograms: null,
            totalAnnualCosts: null,
            priority: null,
            use: false,
            custom: false,
            futureMarketingPlan: [],
        },
        {
            id: `MPW#${year}#WORKSHOP#GUERRILLAMARKETINGIDEAS#${ulid9}`,
            memberId: `MEMBER#${memberId}`,
            secondaryId: `MPW#${year}#WORKSHOP#GUERRILLAMARKETINGIDEAS#9`,
            groupId: ulid9,
            fiscalYear: year,
            tactic: "Door Hangers",
            notes: null,
            numberOfPrograms: null,
            totalAnnualCosts: null,
            priority: null,
            use: false,
            custom: false,
            futureMarketingPlan: [],
        },
        {
            id: `MPW#${year}#WORKSHOP#GUERRILLAMARKETINGIDEAS#${ulid10}`,
            memberId: `MEMBER#${memberId}`,
            secondaryId: `MPW#${year}#WORKSHOP#GUERRILLAMARKETINGIDEAS#10`,
            groupId: ulid10,
            fiscalYear: year,
            tactic: "Preferred Service Provider Kits",
            notes: null,
            numberOfPrograms: null,
            totalAnnualCosts: null,
            priority: null,
            use: false,
            custom: false,
            futureMarketingPlan: [],
        },
        {
            id: `MPW#${year}#WORKSHOP#GUERRILLAMARKETINGIDEAS#${ulid11}`,
            memberId: `MEMBER#${memberId}`,
            secondaryId: `MPW#${year}#WORKSHOP#GUERRILLAMARKETINGIDEAS#11`,
            groupId: ulid11,
            fiscalYear: year,
            tactic: "Home Shows",
            notes: null,
            numberOfPrograms: null,
            totalAnnualCosts: null,
            priority: null,
            use: false,
            custom: false,
            futureMarketingPlan: [],
        },
        {
            id: `MPW#${year}#WORKSHOP#GUERRILLAMARKETINGIDEAS#${ulid12}`,
            memberId: `MEMBER#${memberId}`,
            secondaryId: `MPW#${year}#WORKSHOP#GUERRILLAMARKETINGIDEAS#12`,
            groupId: ulid12,
            fiscalYear: year,
            tactic: "Comfort Stations",
            notes: null,
            numberOfPrograms: null,
            totalAnnualCosts: null,
            priority: null,
            use: false,
            custom: false,
            futureMarketingPlan: [],
        },
        {
            id: `MPW#${year}#WORKSHOP#GUERRILLAMARKETINGIDEAS#${ulid13}`,
            memberId: `MEMBER#${memberId}`,
            secondaryId: `MPW#${year}#WORKSHOP#GUERRILLAMARKETINGIDEAS#13`,
            groupId: ulid13,
            fiscalYear: year,
            tactic: "Oldest Equipment Contests",
            notes: null,
            numberOfPrograms: null,
            totalAnnualCosts: null,
            priority: null,
            use: false,
            custom: false,
            futureMarketingPlan: [],
        },
    ];
}
function createMassMarketingVehicles(memberId, year) {
    const ulid0 = (0, ulid_1.ulid)();
    const ulid1 = (0, ulid_1.ulid)();
    const ulid2 = (0, ulid_1.ulid)();
    const ulid3 = (0, ulid_1.ulid)();
    const ulid4 = (0, ulid_1.ulid)();
    const ulid5 = (0, ulid_1.ulid)();
    const ulid6 = (0, ulid_1.ulid)();
    const ulid7 = (0, ulid_1.ulid)();
    const ulid8 = (0, ulid_1.ulid)();
    const ulid9 = (0, ulid_1.ulid)();
    return [
        {
            id: `MPW#${year}#WORKSHOP#MASSMARKETINGVEHICLES#${ulid0}`,
            memberId: `MEMBER#${memberId}`,
            secondaryId: `MPW#${year}#WORKSHOP#MASSMARKETINGVEHICLES#0`,
            groupId: ulid0,
            fiscalYear: year,
            tactic: "Building Signs",
            notes: null,
            numberOfPrograms: null,
            totalAnnualCosts: null,
            priority: null,
            use: false,
            custom: false,
            futureMarketingPlan: [],
        },
        {
            id: `MPW#${year}#WORKSHOP#MASSMARKETINGVEHICLES#${ulid1}`,
            memberId: `MEMBER#${memberId}`,
            secondaryId: `MPW#${year}#WORKSHOP#MASSMARKETINGVEHICLES#1`,
            groupId: ulid1,
            fiscalYear: year,
            tactic: "Yellow Pages Print",
            notes: null,
            numberOfPrograms: null,
            totalAnnualCosts: null,
            priority: null,
            use: false,
            custom: false,
            futureMarketingPlan: [],
        },
        {
            id: `MPW#${year}#WORKSHOP#MASSMARKETINGVEHICLES#${ulid2}`,
            memberId: `MEMBER#${memberId}`,
            secondaryId: `MPW#${year}#WORKSHOP#MASSMARKETINGVEHICLES#2`,
            groupId: ulid2,
            fiscalYear: year,
            tactic: "Yellow Pages Online",
            notes: null,
            numberOfPrograms: null,
            totalAnnualCosts: null,
            priority: null,
            use: false,
            custom: false,
            futureMarketingPlan: [],
        },
        {
            id: `MPW#${year}#WORKSHOP#MASSMARKETINGVEHICLES#${ulid3}`,
            memberId: `MEMBER#${memberId}`,
            secondaryId: `MPW#${year}#WORKSHOP#MASSMARKETINGVEHICLES#3`,
            groupId: ulid3,
            fiscalYear: year,
            tactic: "Direct Mail",
            notes: null,
            numberOfPrograms: null,
            totalAnnualCosts: null,
            priority: null,
            use: false,
            custom: false,
            futureMarketingPlan: [],
        },
        {
            id: `MPW#${year}#WORKSHOP#MASSMARKETINGVEHICLES#${ulid4}`,
            memberId: `MEMBER#${memberId}`,
            secondaryId: `MPW#${year}#WORKSHOP#MASSMARKETINGVEHICLES#4`,
            groupId: ulid4,
            fiscalYear: year,
            tactic: "Newspaper Ads / Magnets / Sticky Notes",
            notes: null,
            numberOfPrograms: null,
            totalAnnualCosts: null,
            priority: null,
            use: false,
            custom: false,
            futureMarketingPlan: [],
        },
        {
            id: `MPW#${year}#WORKSHOP#MASSMARKETINGVEHICLES#${ulid5}`,
            memberId: `MEMBER#${memberId}`,
            secondaryId: `MPW#${year}#WORKSHOP#MASSMARKETINGVEHICLES#5`,
            groupId: ulid5,
            fiscalYear: year,
            tactic: "Out of Home (e.g., billboards, bus shelters, sports arenas",
            notes: null,
            numberOfPrograms: null,
            totalAnnualCosts: null,
            priority: null,
            use: false,
            custom: false,
            futureMarketingPlan: [],
        },
        {
            id: `MPW#${year}#WORKSHOP#MASSMARKETINGVEHICLES#${ulid6}`,
            memberId: `MEMBER#${memberId}`,
            secondaryId: `MPW#${year}#WORKSHOP#MASSMARKETINGVEHICLES#6`,
            groupId: ulid6,
            fiscalYear: year,
            tactic: "Radio",
            notes: null,
            numberOfPrograms: null,
            totalAnnualCosts: null,
            priority: null,
            use: false,
            custom: false,
            futureMarketingPlan: [],
        },
        {
            id: `MPW#${year}#WORKSHOP#MASSMARKETINGVEHICLES#${ulid7}`,
            memberId: `MEMBER#${memberId}`,
            secondaryId: `MPW#${year}#WORKSHOP#MASSMARKETINGVEHICLES#7`,
            groupId: ulid7,
            fiscalYear: year,
            tactic: "Television",
            notes: null,
            numberOfPrograms: null,
            totalAnnualCosts: null,
            priority: null,
            use: false,
            custom: false,
            futureMarketingPlan: [],
        },
        {
            id: `MPW#${year}#WORKSHOP#MASSMARKETINGVEHICLES#${ulid8}`,
            memberId: `MEMBER#${memberId}`,
            secondaryId: `MPW#${year}#WORKSHOP#MASSMARKETINGVEHICLES#8`,
            groupId: ulid8,
            fiscalYear: year,
            tactic: "Video/Audio Production",
            notes: null,
            numberOfPrograms: null,
            totalAnnualCosts: null,
            priority: null,
            use: false,
            custom: false,
            futureMarketingPlan: [],
        },
        {
            id: `MPW#${year}#WORKSHOP#MASSMARKETINGVEHICLES#${ulid9}`,
            memberId: `MEMBER#${memberId}`,
            secondaryId: `MPW#${year}#WORKSHOP#MASSMARKETINGVEHICLES#9`,
            groupId: ulid9,
            fiscalYear: year,
            tactic: "Reviews/Reputation Management",
            notes: null,
            numberOfPrograms: null,
            totalAnnualCosts: null,
            priority: null,
            use: false,
            custom: false,
            futureMarketingPlan: [],
        },
    ];
}
function createDigitalMarketing(memberId, year) {
    const ulid0 = (0, ulid_1.ulid)();
    const ulid1 = (0, ulid_1.ulid)();
    const ulid2 = (0, ulid_1.ulid)();
    const ulid3 = (0, ulid_1.ulid)();
    const ulid4 = (0, ulid_1.ulid)();
    const ulid5 = (0, ulid_1.ulid)();
    const ulid6 = (0, ulid_1.ulid)();
    const ulid7 = (0, ulid_1.ulid)();
    const ulid8 = (0, ulid_1.ulid)();
    const ulid9 = (0, ulid_1.ulid)();
    return [
        {
            id: `MPW#${year}#WORKSHOP#DIGITALMARKETING#${ulid0}`,
            memberId: `MEMBER#${memberId}`,
            secondaryId: `MPW#${year}#WORKSHOP#DIGITALMARKETING#0`,
            groupId: ulid0,
            fiscalYear: year,
            tactic: "Website",
            notes: null,
            numberOfPrograms: null,
            totalAnnualCosts: null,
            priority: null,
            use: false,
            custom: false,
            futureMarketingPlan: [],
        },
        {
            id: `MPW#${year}#WORKSHOP#DIGITALMARKETING#${ulid1}`,
            memberId: `MEMBER#${memberId}`,
            secondaryId: `MPW#${year}#WORKSHOP#DIGITALMARKETING#1`,
            groupId: ulid1,
            fiscalYear: year,
            tactic: "Website Updates",
            notes: null,
            numberOfPrograms: null,
            totalAnnualCosts: null,
            priority: null,
            use: false,
            custom: false,
            futureMarketingPlan: [],
        },
        {
            id: `MPW#${year}#WORKSHOP#DIGITALMARKETING#${ulid2}`,
            memberId: `MEMBER#${memberId}`,
            secondaryId: `MPW#${year}#WORKSHOP#DIGITALMARKETING#2`,
            groupId: ulid2,
            fiscalYear: year,
            tactic: "Search Engine Optimization",
            notes: null,
            numberOfPrograms: null,
            totalAnnualCosts: null,
            priority: null,
            use: false,
            custom: false,
            futureMarketingPlan: [],
        },
        {
            id: `MPW#${year}#WORKSHOP#DIGITALMARKETING#${ulid3}`,
            memberId: `MEMBER#${memberId}`,
            secondaryId: `MPW#${year}#WORKSHOP#DIGITALMARKETING#3`,
            groupId: ulid3,
            fiscalYear: year,
            tactic: "Local Search Optimization",
            notes: null,
            numberOfPrograms: null,
            totalAnnualCosts: null,
            priority: null,
            use: false,
            custom: false,
            futureMarketingPlan: [],
        },
        {
            id: `MPW#${year}#WORKSHOP#DIGITALMARKETING#${ulid4}`,
            memberId: `MEMBER#${memberId}`,
            secondaryId: `MPW#${year}#WORKSHOP#DIGITALMARKETING#4`,
            groupId: ulid4,
            fiscalYear: year,
            tactic: "Pay-Per-Click",
            notes: null,
            numberOfPrograms: null,
            totalAnnualCosts: null,
            priority: null,
            use: false,
            custom: false,
            futureMarketingPlan: [],
        },
        {
            id: `MPW#${year}#WORKSHOP#DIGITALMARKETING#${ulid5}`,
            memberId: `MEMBER#${memberId}`,
            secondaryId: `MPW#${year}#WORKSHOP#DIGITALMARKETING#5`,
            groupId: ulid5,
            fiscalYear: year,
            tactic: "Google Local Service Ads",
            notes: null,
            numberOfPrograms: null,
            totalAnnualCosts: null,
            priority: null,
            use: false,
            custom: false,
            futureMarketingPlan: [],
        },
        {
            id: `MPW#${year}#WORKSHOP#DIGITALMARKETING#${ulid6}`,
            memberId: `MEMBER#${memberId}`,
            secondaryId: `MPW#${year}#WORKSHOP#DIGITALMARKETING#6`,
            groupId: ulid6,
            fiscalYear: year,
            tactic: "Online Lead Generation (e.g., Service Direct, Angi)",
            notes: null,
            numberOfPrograms: null,
            totalAnnualCosts: null,
            priority: null,
            use: false,
            custom: false,
            futureMarketingPlan: [],
        },
        {
            id: `MPW#${year}#WORKSHOP#DIGITALMARKETING#${ulid7}`,
            memberId: `MEMBER#${memberId}`,
            secondaryId: `MPW#${year}#WORKSHOP#DIGITALMARKETING#7`,
            groupId: ulid7,
            fiscalYear: year,
            tactic: "Online Display Ads",
            notes: null,
            numberOfPrograms: null,
            totalAnnualCosts: null,
            priority: null,
            use: false,
            custom: false,
            futureMarketingPlan: [],
        },
        {
            id: `MPW#${year}#WORKSHOP#DIGITALMARKETING#${ulid8}`,
            memberId: `MEMBER#${memberId}`,
            secondaryId: `MPW#${year}#WORKSHOP#DIGITALMARKETING#8`,
            groupId: ulid8,
            fiscalYear: year,
            tactic: "Video (i.e., email, YouTube, website)",
            notes: null,
            numberOfPrograms: null,
            totalAnnualCosts: null,
            priority: null,
            use: false,
            custom: false,
            futureMarketingPlan: [],
        },
        {
            id: `MPW#${year}#WORKSHOP#DIGITALMARKETING#${ulid9}`,
            memberId: `MEMBER#${memberId}`,
            secondaryId: `MPW#${year}#WORKSHOP#DIGITALMARKETING#9`,
            groupId: ulid9,
            fiscalYear: year,
            tactic: "Social Media",
            notes: null,
            numberOfPrograms: null,
            totalAnnualCosts: null,
            priority: null,
            use: false,
            custom: false,
            futureMarketingPlan: [],
        },
    ];
}
function createAdditionalIdeas(memberId, year) {
    const ulid0 = (0, ulid_1.ulid)();
    const ulid1 = (0, ulid_1.ulid)();
    const ulid2 = (0, ulid_1.ulid)();
    const ulid3 = (0, ulid_1.ulid)();
    const ulid4 = (0, ulid_1.ulid)();
    const ulid5 = (0, ulid_1.ulid)();
    return [
        {
            id: `MPW#${year}#WORKSHOP#ADDITIONALIDEAS#${ulid0}`,
            memberId: `MEMBER#${memberId}`,
            secondaryId: `MPW#${year}#WORKSHOP#ADDITIONALIDEAS#0`,
            groupId: ulid0,
            fiscalYear: year,
            tactic: "Outbound Calling",
            notes: null,
            numberOfPrograms: null,
            totalAnnualCosts: null,
            priority: null,
            use: false,
            custom: false,
            futureMarketingPlan: [],
        },
        {
            id: `MPW#${year}#WORKSHOP#ADDITIONALIDEAS#${ulid1}`,
            memberId: `MEMBER#${memberId}`,
            secondaryId: `MPW#${year}#WORKSHOP#ADDITIONALIDEAS#1`,
            groupId: ulid1,
            fiscalYear: year,
            tactic: "Unsold Job Folders",
            notes: null,
            numberOfPrograms: null,
            totalAnnualCosts: null,
            priority: null,
            use: false,
            custom: false,
            futureMarketingPlan: [],
        },
        {
            id: `MPW#${year}#WORKSHOP#ADDITIONALIDEAS#${ulid2}`,
            memberId: `MEMBER#${memberId}`,
            secondaryId: `MPW#${year}#WORKSHOP#ADDITIONALIDEAS#2`,
            groupId: ulid2,
            fiscalYear: year,
            tactic: "Cross-Selling (e.g., invoice attachment)",
            notes: null,
            numberOfPrograms: null,
            totalAnnualCosts: null,
            priority: null,
            use: false,
            custom: false,
            futureMarketingPlan: [],
        },
        {
            id: `MPW#${year}#WORKSHOP#ADDITIONALIDEAS#${ulid3}`,
            memberId: `MEMBER#${memberId}`,
            secondaryId: `MPW#${year}#WORKSHOP#ADDITIONALIDEAS#3`,
            groupId: ulid3,
            fiscalYear: year,
            tactic: "Niche Programs (e.g., single women, aging in place)",
            notes: null,
            numberOfPrograms: null,
            totalAnnualCosts: null,
            priority: null,
            use: false,
            custom: false,
            futureMarketingPlan: [],
        },
        {
            id: `MPW#${year}#WORKSHOP#ADDITIONALIDEAS#${ulid4}`,
            memberId: `MEMBER#${memberId}`,
            secondaryId: `MPW#${year}#WORKSHOP#ADDITIONALIDEAS#4`,
            groupId: ulid4,
            fiscalYear: year,
            tactic: "Happy Calls",
            notes: null,
            numberOfPrograms: null,
            totalAnnualCosts: null,
            priority: null,
            use: false,
            custom: false,
            futureMarketingPlan: [],
        },
        {
            id: `MPW#${year}#WORKSHOP#ADDITIONALIDEAS#${ulid5}`,
            memberId: `MEMBER#${memberId}`,
            secondaryId: `MPW#${year}#WORKSHOP#ADDITIONALIDEAS#5`,
            groupId: ulid5,
            fiscalYear: year,
            tactic: "Coloring Books",
            notes: null,
            numberOfPrograms: null,
            totalAnnualCosts: null,
            priority: null,
            use: false,
            custom: false,
            futureMarketingPlan: [],
        },
    ];
}
function createMPWWorkshopData(memberId, year) {
    return {
        id: `MPW#${year}#WORKSHOP`,
        memberId: `MEMBER#${memberId}`,
        secondaryId: `MPW#${year}#WORKSHOP`,
        fiscalYear: year,
        fiscalYearEnd: null,
        marketingWorksheet: {
            annualMarketingBudget: null,
            basics: {
                items: createBasics(memberId, year),
                canAdd: false,
            },
            retainAndGrowCurrentCustomers: {
                items: createRetainAndGrowCurrentCustomers(memberId, year),
                canAdd: true,
            },
            guerrillaMarketingIdeas: {
                items: createGuerrillaMarketingIdeas(memberId, year),
                canAdd: true,
            },
            massMarketingVehicles: {
                items: createMassMarketingVehicles(memberId, year),
                canAdd: true,
            },
            digitalMarketing: {
                items: createDigitalMarketing(memberId, year),
                canAdd: true,
            },
            additionalIdeas: {
                items: createAdditionalIdeas(memberId, year),
                canAdd: true,
            },
        },
    };
}
exports.createMPWWorkshopData = createMPWWorkshopData;
