import { OptionsObject, SnackbarKey, SnackbarMessage } from "notistack";

export enum MessageEnums {
  // General
  ACCESS_DENIED = "You are not authorized to access that page",
  SUCCESSFUL_REQUEST = "The request succeeded",
  // Profit Pricer
  NOT_FOUND_PROFIT_PRICER = "Profit Pricer not found",
  SAVED_PROFIT_PRICER = "Successfully saved Profit Pricer",
  UPDATED_PROFIT_PRICER = "Successfully updated Profit Pricer",
  UPDATED_RATES = "Successfully updated Rates",
  UPDATED_PRICING = "Successfully updated Pricing",
  SAVED_PRICING = "Successfully saved Pricing Data",
  // Company Management
  ADDED_DEPARTMENT = "Successfully added Company Management Department",
  UPDATED_DEPARTMENT = "Successfully updated Company Management Department",
  UPDATED_PP_DEPARTMENT = "Successfully updated Department",
  ADD_DEPARTMENTS = "You need to add a department",
  DELETED_DEPARTMENT = "Successfully deleted Company Management Department",
  DELETED_DEPARTMENTS = "Successfully deleted Company Management Departments",
  DELETED_NON_WORKING_DAY = "Successfully deleted Non Working Day",
  UPDATED_COMPANY_INFORMATION = "Successfully updated Company Information",
  SAVED_COMPANY_INFORMATION = "Successfully saved Company information",
  ERROR_DEPARTMENT_CREATE = "Cannot add Department, please check errors",
  // Notes
  CREATED_NOTE = "Note Successfully Created",
  UPDATED_NOTE = "Note Successfully Updated",
  DELETE_NOTES = "Notes Successfully Deleted",
  // Action Plan
  ADDED_ACTION_PLAN = "Successfully added Action Plan",
  UPDATED_ACTION_PLAN = "Successfully updated and saved Action Plan",
  DELETED_SINGLE_ACTION_PLAN = "Action Plan Deleted Successfully",
  DELETED_MULTIPLE_ACTION_PLANS = "Action Plans Deleted Successfully",
  // 3 Day Call Board
  UPDATED_THREE_DAY_CALL_BOARD = "Successfully updated 3 Day Call Board",
  INVALID_DEPARTMENT = "That department is invalid",
  // Daily Pace
  UPDATED_DAILY_PACE_SETUP = "Successfully updated Daily Pace Budget",
  UPDATED_NON_MONTHLY_DATA = "Successfully updated Non Monthly Data",
  UPDATED_MONTHLY_DATA = "Successfully updated Monthly Data",
  UPDATED_SERVICE_AGREEMENT = "Successfully updated Service Agreement",
  UPDATED_DAILY_PACE_TABLE_DATA = "Successfully updated Daily Pace Data",
  // Overheads
  UPDATED_OVERHEADS = "Overhead data has been updated",
  OVERHEADS_SAVE_SUCCESS = "Successfully saved Overhead data",
  OVERHEADS_DELETE_SUCCESS = "Successfully deleted Overhead data",
  DELETED_SINGLE_OVERHEAD = "Overhead deleted successfully",
  DELETED_MULTIPLE_OVERHEAD = "Overheads deleted successfully",
  CREATE_OVERHEAD = "Overhead added successfully",
  ERROR_OVERHEAD_CREATE = "Cannot add Overhead, please check errors",
  ERROR_OVERHEAD_MONTHS_EXCEED_12 = "Cannot save Overheads, months in current year cannot exceed 12",
  // PreWork
  UPDATED_COMPLETION_STATUS = "Successfully Updated Completion Status for PreWork",
  // Workshop
  UPDATED_CONV_AVG_SALE = "Successfully Saved Conversion and Avg Sale",
  UPDATED_MAX_CAPACITY = "Successfully Saved Max Capacity",
  UPDATED_CALL_COUNT = "Successfully Saved Call Count",
  UPDATED_SEASONALITY_OVERRIDES = "Successfully updated Seasonality",
  UPDATED_DIRECT_COSTS = "Successfully Saved Direct Costs",
  UPDATED_OVERHEAD_POSITIONS = "Successfully Saved Overhead Positions",
  SAVED_SERVICE_AGREEMENTS = "Successfully Saved Service Agreement Data",
  ERROR_SERVICE_AGREEMENTS = "There was an error during save",
  ERROR_CUSTOM_COMPANY = "There was an error on save of your custom company",
  VALIDATION_ERROR = "Not All Required Fields Are Filled",
  CRM_CONNECTION_ISSUE = "There was an issue connecting to CRM, some features might not be available.",
  PDF_GENERATED = "Successfully made PDF",
  PDF_FAILED = "Failed Generating PDF",
  EXCEL_DOC_CREATED = "Successfully Exported Excel Sheet",
  EXCEL_DOC_FAILED = "Failed to Export Excel Sheet",
  EXPORT_TEST_TABLE_CREATED = "Successfully Created Test Table",
  EXPORT_TEST_TABLE_FAILED = "Failed to Create Test Table",
  UPDATED_SETTINGS = "Successfully Updated Settings",
  // Tracking
  TRACKING_SEARCH_SELECT_ISSUE = "Make sure to select all options",
  UPDATED_DEPARTMENT_TRACKING = "Successfully Saved Department Tracking",
  UPDATED_COMPANY_VARIANCE = "Successfully Saved Company Variance",
  UPDATED_OKPI_TRACKING = "Successfully Saved Operational KPI",
  UPDATED_MPB_TRACKING = "Successfully Saved Monthly Plan",
  UPDATED_DMT_INFORMATION = "Successfully updated Digital Metrics Tracking",
  UNLOCKED_TRACKING = "Successfully Unlocked Tracking",
  // DMT
  DELETED_DMT = "Delete was successful",
  SAVED_DMT = "Saved",
  // Error Reporting
  ERROR_REPORTED = "Error report has been sent to IT Team",
  ERROR_REPORT_FAILED = "There was an issue sending your report",
  //Profit report
  UPDATED_PROFIT_REPORT = "Successfully Saved Profit Report",
}
// TP-690 JAH: Updating the messaging for the new CRM connections
export type MessagingEnumSelections = keyof typeof MessageEnums | string;
export type MessagingStatusTypes = "success" | "info" | "warning" | "error";
export type MessageEnqueueSnackbar = (
  message: SnackbarMessage,
  options?: OptionsObject,
) => SnackbarKey;
export const addMessage = (
  text: MessagingEnumSelections,
  status: MessagingStatusTypes,
  enqueueSnackbar: MessageEnqueueSnackbar,
): void => {
  text = MessageEnums[text] || text;
  enqueueSnackbar(text, {
    variant: status,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    //KRJ We could add this here (or as an optional prop) to prevent multiple of the same snackbar being rendered
    //https://notistack.com/features/basic#prevent-duplicate
    // preventDuplicate: true,
  });
};
