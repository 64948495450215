import React from "react";
import { ListboxOption } from "@headlessui/react";
import { ManageDepartment } from "@nexstar-network/shared-utils";
import CheckIcon from "../icons/CheckIcon";

type NexstarSelectItemProps = {
  keyValue: string;
  value: string | number | ManageDepartment | Record<string, any>;
  text: string;
  dataTestId?: string;
  id: string;
  disabled?: boolean;
  onClick?: any;
  wrapText?: boolean;
};

//TODO: Move to a helper
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function NexstarSelectItem({
  keyValue,
  value,
  text,
  dataTestId,
  id,
  disabled,
  onClick,
  wrapText = false,
}: NexstarSelectItemProps) {
  return (
    <ListboxOption
      as="li"
      onClick={onClick}
      key={`${keyValue}-item`}
      disabled={disabled}
      className={({ focus }) =>
        classNames(
          focus ? "text-black bg-NexstarGray" : "text-gray-900",
          "relative cursor-default select-none py-2 pl-3 pr-9",
        )
      }
      value={value}
      data-testid={dataTestId ? dataTestId : id}
      id={id}
    >
      {({ selected }) => (
        <>
          <div className="flex items-center">
            <span
              className={`${
                selected
                  ? "font-semibold"
                  : `font-normal block ${!wrapText && "truncate"}`
              } ${disabled ? "text-red-300" : "text-black"}`}
            >
              {text}
            </span>
          </div>
          {selected && !disabled ? (
            <span
              className={classNames(
                "text-black absolute inset-y-0 right-0 flex items-center pr-2",
              )}
            >
              <CheckIcon color="black" />
            </span>
          ) : null}
        </>
      )}
    </ListboxOption>
  );
}

export default NexstarSelectItem;
