import * as React from "react";
import { Fragment, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Transition,
} from "@headlessui/react";
import { empty, MemberType } from "@nexstar-network/shared-utils";
import { useNavigate } from "react-router-dom";
import AddCompanyDialog from "../context/user/AddCompanyDialog";
import { useUserContext } from "../context/user/UserContext";
import ChevronUpDownIcon from "../../utils/components/icons/ChevronUpDownIcon";
import CheckIcon from "../../utils/components/icons/CheckIcon";

export default function CompanySelector(): JSX.Element {
  const { getAccessTokenSilently } = useAuth0();
  const { getUser } = useUserContext();
  const {
    companyOptions,
    setCompanyOptions,
    handleCompanyChange,
    selectedCompany,
    shouldDisplay,
    disabled,
  } = useUserContext();
  const [open, toggleOpen] = React.useState(false);
  const [query, setQuery] = useState("");
  const [newCompanyData, setNewCompanyData] = useState<MemberType>({
    companyName: "",
    companyCountry: "",
    companyState: "",
    companyCity: "",
    companyZipCode: "",
    coachId: "",
    companyId: 0,
    companyLatitude: "",
    companyLongitude: "",
    id: "",
    memberId: null,
  });
  const navigate = useNavigate();

  if (
    (!shouldDisplay && window.location.pathname === "/maintenance") ||
    disabled
  ) {
    return null;
  }
  const filteredCompanies =
    query === ""
      ? companyOptions
      : companyOptions.filter((company) => {
          return company.companyName
            .toLowerCase()
            .includes(query.toLowerCase());
        });
  const handleCompanySelect = (selected) => {
    if (empty(selected)) {
      return;
    } else if (selected.companyId) {
      if (selectedCompany.companyName !== selected.companyName) {
        navigate("/");
      }
      handleCompanyChange(selected);
    } else {
      toggleOpen(true);
      setNewCompanyData({
        coachId: "",
        companyId: 0,
        companyLatitude: "",
        companyLongitude: "",
        id: "",
        memberId: null,
        companyName: selected.companyName,
        companyCountry: "",
        companyState: "",
        companyCity: "",
        companyZipCode: "",
      });
    }
  };
  const generateOptions = () => {
    return filteredCompanies.map((company) => {
      return (
        <ComboboxOption
          as="li"
          key={company.companyId}
          className={({ focus }) =>
            `relative cursor-default select-none py-2 pl-3 pr-10 ${
              focus ? "bg-NexstarGray-dark" : "text-gray-900"
            }`
          }
          value={company}
        >
          {() => (
            <>
              <span
                className={`block truncate ${
                  selectedCompany.companyId === company.companyId
                    ? "font-bold"
                    : "font-normal"
                }`}
              >
                {company.companyName}
              </span>
              {selectedCompany.companyId === company.companyId ? (
                <span className="text-black absolute inset-y-0 right-0 flex items-center pr-2">
                  <CheckIcon color="black" />
                </span>
              ) : null}
            </>
          )}
        </ComboboxOption>
      );
    });
  };
  return (
    <>
      <div id="company-selector-top-nav" className="top-16 w-40 xl:w-60">
        <Combobox value={selectedCompany} onChange={handleCompanySelect}>
          <div className="relative mt-1">
            <div className="relative w-full cursor-default mx-auto">
              <ComboboxInput
                className={`${
                  !query ? "bg-NexstarSoftGray" : "bg-white"
                } w-full content-center relative cursor-default border border-NexstarGray-dark focus:ring-1 focus:ring-NexstarBlue-light focus:border-NexstarBlue-light py-1 pl-2 pr-8 text-left focus:outline-none text-sm`}
                // @ts-ignore
                displayValue={(company) => company.companyName}
                onChange={(event) => setQuery(event.target.value)}
              />
              <ComboboxButton className="absolute w-[24px] h-[28px] inset-y-0 top-[1px] right-[1px] flex items-center justify-center bg-NexstarGray">
                <ChevronUpDownIcon color="gray" size="xsmall" />
              </ComboboxButton>
            </div>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              afterLeave={() => setQuery("")}
            >
              <ComboboxOptions
                as="ul"
                className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-[9999]"
              >
                {filteredCompanies.length === 0 && query !== "" ? (
                  <ComboboxOption
                    as="li"
                    key="create"
                    value={{ companyId: null, companyName: query }}
                    className={({ focus }) =>
                      `relative cursor-default select-none py-2 pl-3 pr-10 ${
                        focus ? "bg-NexstarGray-dark" : "text-gray-900"
                      }`
                    }
                  >
                    Create "{query}"
                  </ComboboxOption>
                ) : (
                  generateOptions()
                )}
              </ComboboxOptions>
            </Transition>
          </div>
        </Combobox>
      </div>
      <AddCompanyDialog
        open={open}
        setOpen={() => toggleOpen(false)}
        user={getUser()}
        getAccessTokenSilently={getAccessTokenSilently}
        endpoint={`${process.env.REACT_APP_API_BASE_URL}graphql`}
        companyOptions={companyOptions}
        setCompanyOptions={setCompanyOptions}
        newCompanyData={newCompanyData}
        setNewCompanyData={setNewCompanyData}
      />
    </>
  );
}
