import React, { ReactElement, useEffect, useState } from "react";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { useAuth0 } from "@auth0/auth0-react";
import { CRMUser, UserIdOrDefault } from "../../../utils/helpers/UserHelper";
import { UseQueryType } from "../../../types/ErrorMessageType";
import { getUserAlerts, markAsRead } from "../utils/alertsRequests";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import InformationIcon from "../../../utils/components/icons/InformationIcon";
import ButtonToolTip from "../../../utils/components/tailwind/ButtonToolTip";
import BellIcon from "../../../utils/components/icons/BellIcon";
import { DateTime } from "luxon";
import { empty } from "@nexstar-network/shared-utils";
import { handleMarkSingleAsRead, orderedAlerts } from "../utils/alertsHelpers";
import { enqueueSnackbar } from "notistack";
import AlertsToggle from "./AlertsToggle";

export default function AlertsDropdownMenu(): ReactElement {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();
  const { user } = useAuth0();
  const userId = UserIdOrDefault(user as CRMUser);
  const [displayOnlyUnRead, setDisplayOnlyUnRead] = useState<boolean>(true);
  const [numberOfUnread, setNumberOfUnread] = useState(0);
  const [alerts, setAlerts] = useState<Array<any>>([]);

  const {
    data: alertsData,
    isLoading: alertsLoading,
    refetch,
    error: alertsError,
  }: UseQueryType<any> = useQuery({
    queryKey: ["getNotifications"],
    queryFn: () => getUserAlerts(userId, getAccessTokenSilently),
  });
  const markAlertsAsRead = useMutation({
    mutationFn: (alertID: number) =>
      markAsRead(userId, alertID, getAccessTokenSilently),
  });

  useEffect(() => {
    if (alertsData && alertsData.alerts && !alertsLoading) {
      let numberOfUnreadAlerts = 0;
      for (let i = 0; i < alertsData.alerts.length; i++) {
        if (empty(alertsData.alerts[i].pivot.readDate)) {
          numberOfUnreadAlerts++;
        }
      }
      setNumberOfUnread(numberOfUnreadAlerts);
      setAlerts(alertsData.alerts);
    }
  }, [alertsData]);

  const handleItemClick = (ev) => {
    ev.stopPropagation();
  };
  if (alertsError) throw new Error(alertsError.message);
  const DisplayAlerts = (alertsToDisplay: any[]) => {
    if (alerts && alerts.length > 0 && !alertsLoading) {
      const displayItems: any[] = [];
      let displayCount = 0;
      alertsToDisplay.map((alert) => {
        if (displayOnlyUnRead) {
          if (empty(alert.pivot.readDate)) {
            if (displayCount < 15) {
              displayItems.push(alert);
              displayCount++;
            }
          }
        } else {
          if (displayCount < 15) {
            displayItems.push(alert);
          }
        }
      });
      if (displayItems.length) {
        return displayItems.map((alert) => (
          <MenuItem
            as="a"
            key={`${alert.alertID}-item`}
            href="/notifications"
            className="pt-4 pb-3 flex grow"
          >
            {({ focus }) => (
              <div
                key={`${alert.alertID}-button`}
                className={`${
                  focus ? "bg-NexstarSoftGray" : "text-gray-900"
                } w-full items-center px-4 py-1 text-sm h-16 border-0`}
              >
                <div className="flex flex-col-3 justify-start">
                  <div className="pr-3 mt-[-2]">
                    <InformationIcon color="NexstarSkyBlue" size="medium" />
                  </div>
                  <div
                    className={`flex-row flex-wrap text-[17px] font-[450] ${
                      !empty(alert.pivot.readDate)
                        ? "text-NexstarGray-dark"
                        : "text-black"
                    } w-full text-left justify-between`}
                  >
                    {alert.preview}
                  </div>
                  {empty(alert.pivot.readDate) ? (
                    <ButtonToolTip tooltip="Mark as read">
                      <button
                        className="h-[24px] w-[24px] hover:bg-NexstarGray-dark hover:rounded-full"
                        onClick={(ev) =>
                          handleMarkSingleAsRead(
                            ev,
                            alert.alertID,
                            numberOfUnread,
                            setNumberOfUnread,
                            markAlertsAsRead,
                            queryClient,
                            refetch,
                            true,
                            enqueueSnackbar,
                          )
                        }
                      >
                        <div className="bg-NexstarBlue-dark rounded-full w-[7px] h-[7px] font-bold text-white text-xs ml-2" />
                      </button>
                    </ButtonToolTip>
                  ) : (
                    <button className="h-[24px] w-[24px]">
                      <div className="bg-NexstarGray-dark rounded-full w-[7px] h-[7px] font-bold text-white text-xs ml-2" />
                    </button>
                  )}
                </div>
                <div className="flex flex-col-1 justify-start">
                  <div
                    className={`flex-row w-full text-[12px] text-left ml-10 ${
                      !empty(alert.pivot.readDate)
                        ? "text-NexstarGray-dark"
                        : "text-black"
                    }`}
                  >
                    Updated At:{" "}
                    <span>
                      {DateTime.fromISO(alert.created_at)
                        .toLocal()
                        .toFormat("MM-dd-yyyy")}
                    </span>
                  </div>
                </div>
              </div>
            )}
          </MenuItem>
        ));
      }
    }
    return (
      <MenuItem disabled as="a" key="no_notifications">
        <div className="h-[125px] pt-10 text-xl text-black text-center">
          No Unread Alerts
        </div>
      </MenuItem>
    );
  };
  return (
    <div
      id="TopBarNotifications"
      className="relative flex align-center ml-4 over"
    >
      <Menu>
        <>
          <div>
            <MenuButton className="inline-flex w-full justify-center mt-[-15px]">
              <BellIcon size="medium" className="ml-2" />
              {numberOfUnread > 0 ? (
                <div className="bg-red-600 rounded-full w-[20px] h-[20px] font-bold text-white text-xs pt-[2px] text-center ml-[-10px] mr-[-8px]">
                  {numberOfUnread}
                </div>
              ) : null}
            </MenuButton>
          </div>
          <MenuItems
            onClick={(ev) => handleItemClick(ev)}
            className="absolute z-10 right-0 mt-5 w-[650px] origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none max-h-[400px] overflow-auto"
          >
            <MenuItem disabled>
              <div className="flex justify-between font-bold h-16 text-black text-center pt-2">
                <div className="ml-3 mt-2 text-2xl">Alerts</div>
                <div className="justify-end text-black mr-3 mt-3">
                  <AlertsToggle
                    onChange={() => setDisplayOnlyUnRead(!displayOnlyUnRead)}
                    checked={displayOnlyUnRead}
                    name="Mark All As Read"
                    label="Only Show Unread"
                  />
                </div>
              </div>
            </MenuItem>
            {DisplayAlerts(orderedAlerts(alerts))}
          </MenuItems>
        </>
      </Menu>
    </div>
  );
}
