import React, { ReactElement } from "react";

type ButtonProps = {
  bgColor?: string;
  disabledBgColor?: string;
  children: React.ReactNode;
  dataTestId?: string;
  disabled?: boolean;
  id: string;
  onClick?: any;
  padding?: string;
  textColor?: string;
  textSize?: string;
  width?: string;
  value?: string | number;
  additionalClasses?: string;
  name?: string;
  border?: string;
};

export default function Button({
  bgColor = "bg-NexstarOrange",
  children,
  dataTestId,
  disabled,
  disabledBgColor = "bg-NexstarGray",
  id,
  onClick,
  padding = "px-4 py-2",
  textColor = "text-white",
  textSize = "text-base",
  width = "w-auto",
  value,
  additionalClasses,
  name,
  border = "border-transparent",
}: ButtonProps): ReactElement {
  return (
    <button
      onClick={onClick}
      value={value}
      id={id}
      type="button"
      disabled={disabled}
      name={name}
      className={`inline-flex gap-2 items-center justify-center ${width} border-2 ${border} rounded-xl ${padding} ${textSize} leading-5 font-bold ${textColor} uppercase transition-all focus-visible:outline-none focus-visible:ring-NexstarSkyBlue focus-visible:ring-2 focus-visible:${bgColor} focus-visible:ring-offset-1 max-w-sm ${
        additionalClasses ?? ""
      } ${
        disabled
          ? disabledBgColor
          : `${bgColor} hover:rounded-tl-none hover:rounded-br-none hover:shadow-nexstar`
      }`}
      data-testid={dataTestId ? dataTestId : id}
    >
      {children}
    </button>
  );
}
